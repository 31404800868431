<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import { Order } from '/~/types/api/order'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { usePayId, PayIdOrder } from '/~/composables/payment-methods'
import CompletePayId from '/~/templates/bill-payments-v1_5/views/payments/make/components/how-to-pay/complete-payid.vue'

const router = useRouter()

const props = defineProps<{
  order: Order
  flowType: FlowType
}>()

const { onCancelPaymentOrder } = usePayId()

const payIdOrder = computed(() => {
  return new PayIdOrder({
    id: props.order.paymentMethods?.[0].name,
    reference: props.order.number,
    amount: props.order?.total,
    expiresAt: props.order?.expiresAt,
  })
})

function goToActivityListPage() {
  router.back()
}
</script>
<template>
  <div class="my-5 -mx-6 border-b border-eonx-neutral-400 pb-5">
    <div class="mx-6">
      <div class="text-lg font-bold">Pay with PayID</div>
      <complete-pay-id
        class="mt-4"
        :pay-id-order="payIdOrder"
        @cancel="
          onCancelPaymentOrder(order.number, goToActivityListPage, flowType)
        "
        @continue="goToHomePage()"
      />
    </div>
  </div>
</template>
